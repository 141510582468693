import { storage } from '../firebase'

export default {
	data() {
		return {
			fireBaseStoreageFile: {}
		}
	},
	methods: {
		async fireBaseStorageUpload(files, directory) {

			if(files.length) {
				files = Array.from(files)
			} else {
				files = [files]
			}

			files.forEach(function(file, index) {

				let d = new Date();
				const filename = d.getHours() + '' + d.getMinutes() + '' + d.getSeconds() + '' + index + '' + file.name

				// Upload file and metadata
				var uploadTask = storage.ref().child(directory + '/' + filename).put(file, {contentType: file.type})

				// Listen for state changes, errors, and completion of the upload.
				uploadTask.on('state_changed',
					(snapshot) => {
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						//var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
						//console.log('Upload is ' + progress + '% done')

						switch (snapshot.state) {
							case 'paused':
								//console.log('Upload is paused')
								break
							case 'running':
								//console.log('Upload is running')
								break
						}
					},
					(error) => {
						// A full list of error codes is available at
						// https://firebase.google.com/docs/storage/web/handle-errors
						switch (error.code) {
							case 'storage/unauthorized':
								// User doesn't have permission to access the object
								break;
							case 'storage/canceled':
								// User canceled the upload
								break;
							case 'storage/unknown':
								// Unknown error occurred, inspect error.serverResponse
								break;
						}
					},
					() => {
						// Upload completed successfully, now we can get the download URL
						uploadTask.snapshot.ref.getDownloadURL().then( (url) => {
							this.fireBaseStoreageFile = {filename: filename, url: url}
						})
					}
				)
			}.bind(this))
		}
	}
}